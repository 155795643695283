import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import authService from './utils/AuthenticationService';
import ProtectedRoutes from './utils/ProtectedRoutes';
import UnProtectedRoutes from './utils/UnProtectedRoutes';
import { AuthProvider } from './utils/context/authContext';

import Home from './pages';
import SigninPage from './pages/signin';
import SettingsPage from './pages/settings';
import MapPage from './pages/map';
import ProfilePage from './pages/profile';

authService.init();

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<MapPage/>} exact/>
            <Route path="/profile" element={<ProfilePage/>} exact/>
          </Route>
          <Route element={<UnProtectedRoutes />}>
            <Route path="/login" element={<Home/>} exact />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;