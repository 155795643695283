import { Outlet, Navigate } from "react-router-dom";
import React, { useContext } from 'react';
import { AuthContext } from './context/authContext';

const ProtectedRoutes = () => {

    const { userAuthenticated } = useContext(AuthContext);

    return (
        userAuthenticated ? <Outlet/> : <Navigate to="/login" />
    )
}

export default ProtectedRoutes