import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import authService from './AuthenticationService';
import wsp_logo from '../images/wsp_logo.png';
import { getAssetLayerImpactSeverity } from './apiRequestBuilder';
import * as tables from './Tables';


const formatLayerTitle = (layerTitle) => {
    let title;
    if (layerTitle.includes('(')){  // creating column name to hold maximum risk for that climate layer (split is responsible for separating date which may be in title)
        title = layerTitle.split(' (');
    }else {
        title = layerTitle.split(/(\d+)/);
    }
    return title[0];
}

export const generateSummaryReport = async (tableApi, analysisData, clientConfig, allAnalysisRiskData) => {

    const filteredSortedIds = gridFilteredSortedRowIdsSelector(tableApi);
    const filteredRows = tableApi.current.getSortedRows().filter((row) => filteredSortedIds.includes(row.id + analysisData.assetLayerId));
    const tableColumns = tableApi.current.getAllColumns();   

    const assetType = analysisData.assetTitle;
    const climateLayerParameters = analysisData.climateLayers.map((layer) => formatLayerTitle(layer.title));

    const climateLayersScenario = analysisData.climateLayers[0].scenario;
    const climateLayersTimeframe = analysisData.climateLayers[0].timeframe;

    const monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const d = new Date();
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();

    const user = await authService.getUser();

    let projectName = '<Project Name>';
    let projectArea = '<Project Area>';
    let projectDescription = '<Project Description>';
    let projectSubHeading = '<Project Subheading>';
    let projectNumber = '<Project Number>';

    if (clientConfig != null) {
        projectName = clientConfig.hasOwnProperty("projectName") ? clientConfig.projectName : projectName;
        projectArea = clientConfig.hasOwnProperty("projectArea") ? clientConfig.projectArea : projectArea;
        projectDescription = clientConfig.hasOwnProperty("projectDescription") ? clientConfig.projectDescription : projectDescription;
        projectSubHeading = clientConfig.hasOwnProperty("projectSubHeading")? clientConfig.projectSubHeading: "";
        projectNumber = clientConfig.hasOwnProperty("projectNumber")? clientConfig.projectNumber: "";
    }

    const dataDescription_paragraph_1 = `This report presents a set of climate parameters describing the climatic and meteorological phenomena relevant to the ${projectArea} project scope and is meant to provide input into the broader Climate Hazard Risk Review.`;
    const dataDescription_paragraph_2 = `Global Climate Model (GCM) simulations are created for a range of possible emissions scenarios. The Intergovernmental Panel on Climate Change (IPCC) supported the development of four Representative Concentration Pathways (RCP) as part of a new initiative for the Fifth Assessment Report. RCP 2.6, 4.5, 6.0, and 8.5 reflect various levels of climate change mitigation efforts with the numbers corresponding to potential radiative forcing levels reached by 2100. `;
    const dataDescription_paragraph_3 = `The 2021 IPCC AR6 features new state-of-the-art CMIP6 models. The modelling community has developed a new set of emissions scenarios driven by different socioeconomic assumptions (the Shared Socioeconomic Pathways (SSPs). These SSPs look at five different ways in which the world might evolve in the absence of climate policy and how different levels of climate change mitigation could be achieved when the mitigation targets of AR5's Representative Concentration Pathways (RCPs) are combined with the SSPs.`;
    const dataDescription_paragraph_4 = `The four RCPs that examined different possible future Greenhouse Gas (GHG) emissions included RCP2.6, RCP4.5, RCP6.0, and RCP8.5 - have new versions in CMIP6. These updated scenarios are called SSP1-2.6 (low end of spectrum), SSP2-4.5 (medium end of spectrum), SSP4-6.0 (medium to high end of spectrum), and SSP5-8.5 (high end of spectrum), each of which result in similar 2100 radiative forcing levels as their predecessor in AR5 models. For further details on CMIP5 & CMIP6 data please reference O'Neill et al. (2016).`;
    const dataDescription_paragraph_5 = `For this report, the ${climateLayersScenario} scenarios were used. `;


    let mapContainer = document.getElementById("mapContainer");
    let mapPage = document.getElementById("mapPage");
    const elementsToIgnore = ["analysisResultsTable","mapNavBar"]; 

    

    const mapCanvas = await html2canvas(mapPage, {
        useCORS: true,
        width: mapContainer.offsetWidth,
        height: mapContainer.offsetHeight,
        ignoreElements: function (el) {
            return elementsToIgnore.includes(el.id); 
        }
    });
    const mapImage = mapCanvas.toDataURL('image/png');

    const doc = new jsPDF();
    doc.addImage(mapImage, 'PNG', 10, 10, 190, 95); // Adjust the position and size as needed
    

    // Client information
    const organization = user.profile.organization_id;

    const disclaimer_paragraph_1 = `The climate information presented in this report is not based on analysis generated through this project, but rather a presentation of readily available information from existing sources. As climate science is continually advancing, this report should not be construed as a comprehensive and permanent characterization of historic or future climate projections and should be reviewed and revised periodically.`;
    const disclaimer_paragraph_2 = `WSP Canada Inc (WSP) prepared this report solely for the use of the intended recipient,${user}, in accordance with the professional services agreement. The intended recipient is solely responsible for the disclosure of any information contained in this report. The content and opinions contained in the present report are based on the observations and/or information available to WSP at the time of preparation. If a third party makes use of, relies on, or makes decisions in accordance with this report, said third party is solely responsible for such use, reliance or decisions. WSP does not accept responsibility for damages, if any, suffered by any third party as a result of decisions made or actions taken by said third party based on this report. This limitations statement is considered an integral part of this report.`;

    const summaryRisk = `Table 1 illustrates the range of climate risk scores, consistent with the PIEVC methodology. Table 2 describes the interpretation of each range of risk scores.`;


    // Prepare DataGrid data for pdfMake
    const formatedColumns = tableColumns.filter((col) => col.field != 'detailed_risk')

    let headerNames = [];

    formatedColumns.forEach(col => {
        let isUnique = analysisData.climateLayers.every(x => {
            let title = x.title.replace(/\(.*?\)/, '').trim();
            return col.field !== title;
        });

        if (isUnique && !headerNames.some(h => h.headerName === col.headerName)) {
            headerNames.push(col);
        }
    });

    let idf, idfColumns, tableBodyWithIDF, totalColumns;
    idf = formatedColumns.filter((col) => col.field.includes('IDF'));
    let nonIdfColumns = formatedColumns.filter((col) => !col.field.includes('IDF'));


    const tableBodyWithoutIDF = [
        nonIdfColumns.map((col) => { 
            const columnName = col.headerName ? col.headerName : col.field == 'max_risk' ? 'Max Risk' : col.field;
            return { text: columnName, fontSize: 7, bold: true}; 
        }), 
        ...filteredRows.map((row) => nonIdfColumns.map((col, colIndex) => { 
            return { 
                text: row[col.field], 
                fontSize: 7, 
                bold: colIndex === 0 
            };
        })), 
    ];

    if (idf.length >0){
        idfColumns = [...headerNames, ...idf];
        tableBodyWithIDF = [
            idfColumns.map((col) => { 
                const columnName = col.headerName ? col.headerName : col.field == 'max_risk' ? 'Max Risk' : col.field;
                return { text: columnName, fontSize: 7, bold: true}; 
            }), 
            ...filteredRows.map((row) => idfColumns.map((col, colIndex) => { 
                return { 
                    text: row[col.field], 
                    fontSize: 7, 
                    bold: colIndex === 0 
                };
            })), 
        ];
        totalColumns = Math.max(idfColumns.length, nonIdfColumns.length);
    }
    else{
        totalColumns = nonIdfColumns.length;
    }

    const maxWidth = 4000;
    const maxHeight = 2000;
    
    const minWidth = 200;
    const minHeight = 1000;
    
    const scaleFactor = totalColumns / 90;
    
    let pageWidth = maxWidth * scaleFactor;
    let pageHeight = maxHeight * scaleFactor;
    
    pageWidth = Math.max(pageWidth, minWidth) + 300;
    pageHeight = Math.max(pageHeight, minHeight) + 150;
    
    const pageSize = {
        width: pageWidth,
        height: pageHeight,
    };

    //Appendices
    let appendixA_columns = headerNames.filter(col => col.headerName !== "Max Risk");
    const tableBodyAppendixA = [
        appendixA_columns.map((col) => { 
            const columnName = col.headerName;
            return { text: columnName, fontSize: 7, bold: true}; 
        }), 
        ...filteredRows.map((row) => appendixA_columns.map((col, colIndex) => { 
            return { 
                text: row[col.field], 
                fontSize: 7, 
                bold: colIndex === 0 
            };
        })), 
    ];

        //for Appendix B
        const assetsID = [];  

        filteredRows.forEach((rows) => assetsID.push([rows.id, rows.name]));
        const riskType = analysisData.risk_type;
    
        const appendixBTables = [];
    
        assetsID.forEach((assetID) => {
            const assetRisk = [];
            
            allAnalysisRiskData.forEach((rows) => {
                if (rows.asset_id === assetID[0] && rows.risk_type == riskType && rows.parameter_value != null) {
                    assetRisk.push(rows);
                }
            });
    
            const header_riskType_1 = tables.getDetailsTableColumns(assetRisk, riskType, analysisData.definition);
            const row_riskType_1 = tables.getDetailsTableRows(assetRisk, riskType, analysisData.definition);

            const tableBodyB_riskType_1 = [
                header_riskType_1.map(header => ({ text: header.includes('Placeholder') ? '' : header, fontSize: 7, bold: true })),
            
                ...row_riskType_1.map(row => 
                    header_riskType_1.map((header, colIndex) => {
                        const cellText = row[header] !== undefined ? row[header].toString() : '';
                        
                        const isRiskRow = row['Property'].includes("Risk");
            
                        return {
                            text: cellText,
                            fontSize: 7,
                            fillColor: isRiskRow === true ? tables.getColourCoding(cellText): '', 
                            bold: colIndex === 0 
                        };
                    })
                )
            ];
            
            appendixBTables.push({
                text: `Risk Scores for ${assetID[1]}`, 
                style: 'subheader',
            });
            appendixBTables.push({
                table: {
                    widths: '*',
                    body: tableBodyB_riskType_1
                },
            });

            if (riskType == 2){
                const header_riskType_2 = tables.getDetailsTableColumns2(assetRisk, analysisData.definition);
                const rows_riskType_2 = tables.getDetailsTableRows2(assetRisk, analysisData.definition);

                const tableBodyB_riskType_2 = [
                    header_riskType_2.map(header => ({ text: header, fontSize: 7, bold: true })),
                
                    ...rows_riskType_2.map(row => 
                        header_riskType_2.map((header, colIndex) => {
                            const cellText = row[header] !== undefined ? row[header].toString() : '';
                            
                
                            return {
                                text: cellText,
                                fontSize: 7,
                                fillColor: tables.getColourCoding(cellText), 
                                bold: colIndex === 0 
                            };
                        })
                    )
                ];

                appendixBTables.push({
                    table: {
                        widths: '*',
                        body: tableBodyB_riskType_2
                    },
                });
    
            }
        },
    );

    const userTenantId = user.profile.tenant_id;
    const [request, options] = await getAssetLayerImpactSeverity(userTenantId, analysisData.assetLayerId);
    const response = await fetch(request, options);
    const json = await response.json();
    const impactSeverityHeaders = tables.tableHeaderImpactSeverity(json,[],'headerStyle');
    const impactSeverityRows =  tables.tableRowsImpactSeverity(json,[],'headerStyle');

    const appendixD = [
        impactSeverityHeaders.map(header => ({
            text: header,
            bold: true, 
            fontSize: 7,
            alignment: 'center'
        })),
        ...impactSeverityRows.map(row => row.map(cell => ({
            text: cell,
            fontSize: 7, 
            alignment: 'center'
        })))
    ];

    //Default WSP font is arial and color is #FF5751  #FF4B4B  #FF5751

    const docDefinition = {
        content: [

            
        
            { image: mapImage, width: 515 },
            { text: 'General map of region', style: 'metaDataHeader', lineHeight: 0.3, margin: [0, 10, 0, 20] },

            {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 515,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: 'red',
                  },
                ],
                margin: [0, 10],
            },
      
            {
                columns: [
                    [
                        { text: 'Location', style: 'metaDataHeader' },
                        { text: 'City, Province, Country', style: 'metaDataText' },

                        { text: 'Client', style: 'metaDataHeader' },
                        { text: organization, style: 'metaDataText' },

                        { text: 'Time Horizon', style: 'metaDataHeader' },
                        { text: climateLayersTimeframe, style: 'metaDataText' },

                        { text: 'Climate Scenario', style: 'metaDataHeader' },
                        { text: climateLayersScenario, style: 'metaDataText' },

                        { text: 'Asset Type', style: 'metaDataHeader' },
                        { text: assetType,pageBreak: 'after', style: 'metaDataText' }
                        
                    ], 
                ]
            },


            { text: '1 Product Description', style: 'header' },
            { text: `${projectDescription}`, style: 'metaDataText' },

            { text: '2 Report Objective', style: 'header' },
            { text: 'This report presents a summary of the climate risk analysis results that are provided by DecisionVue: Climate Impacts™ (DecisionVue). DecisionVue is a web-based climate risk analysis tool that provides screening-level climate risk assessments, for portfolios of assets.', style: 'metaDataText' },

            { text: 'This report is generated by DecisionVue, to summarize the climate risk analysis that is assessed by the user. DecisionVue allows the user to select various climate change scenarios, time periods, climate parameters, and categories of assets to evaluate. This report contains a snapshot of the input selections, the background data, and the resulting climate risk analysis. This creates a record of the analysis for future reference and comparison.', style: 'metaDataText' },

            { text: '3 Data', style: 'header' },
            { text: dataDescription_paragraph_1, style: 'metaDataText' },
            { text: dataDescription_paragraph_2, style: 'metaDataText' },
            { text: dataDescription_paragraph_3, style: 'metaDataText' },
            { text: dataDescription_paragraph_4, style: 'metaDataText' },
            { text: dataDescription_paragraph_5, style: 'metaDataText' },
            { text: 'Climate Parameters', style: 'metaDataHeader' },
            { text: 'List of climate parameters selected for the analysis', style: 'metaDataText' },
            { ul: climateLayerParameters.map((param) => ({ text: param, margin: [5, 2] })), style: 'metaDataText'},

            
            {text: '', pageOrientation: 'landscape', pageBreak: 'before'},
            {text: '4 Results', style: 'header'},
            {
                stack: [
                    {text: '4.1 Risk Map', style: 'subheader' },
                    {image: mapImage ,width: 700, length: 400, pageBreakBefore: false},

                ],
                unbreakable: true
            },
            {text: '',pageOrientation: 'portrait', pageBreak: 'after'},
            {text: '4.2 Summary Risks', style: 'subheader'},
            {text: summaryRisk, style: 'metaDataText'},
            {
                text: 'Table 4-1 Risk Assessment Scoring Framework',
                color: '#ff0000', style: 'metaDataText'
            },
            {
                columns: [
                    [
                        {
                            table: {
                                widths: ['15%', '25%', '10%','10%','10%','10%','10%','10%' ],
                                body: tables.tableBodyRiskAssessment_1
                            }
    
                        },
                        {
                            table: {
                                widths: ['15%', '25%', '10%','10%','10%','10%','10%','10%' ],
                                body: tables.tableBodyRiskAssessment_2
                            },
                            layout: {
                                fillColor: function (rowIndex, node, columnIndex) {
                                    return '#ffa500'; 
                                },
                                hLineWidth: function (i, node) {
                                    if (i === 0 || i === 1 || i === 2 || i === 3) {
                                        return 0;
                                    }
                                    return 1; 
                                },
                                vLineWidth: function (i, node) {
                                    if (i === 0 || i === 1 || i === 2) {
                                        return 0;
                                    }
                                    return 1; 
                                },
                                hLineColor: function (i, node) {
                                    return i === 0 || i === 1 || i === 2 || i === 3 ? 'transparent' : 'black';
                                },
                                vLineColor: function (i, node) {
                                    return i === 0 || i === 1 || i === 2 ? 'transparent' : 'black';
                                },
                        }
                    },
                    ],
                ],

            },
            {
                text: 'Table 4-2 Description and Interpretation of Risk Scores',
                color: '#ff0000', style: 'metaDataText'
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['20%', '20%', '20%', '40%'], 
                    body: tables.tableBodyRiskScores
                },
                pageBreak: 'after'
            },

            {text: 'Disclaimer', style: 'subheader'}, 
            {text: disclaimer_paragraph_1, style: 'metaDataText'},
            {text: disclaimer_paragraph_2, style: 'metaDataText'},

        ], 
        header: (currentPage, pageCount) => [
            {
                
              columns: [
                [   { image: wsp_logo, width: 50, margin: [0, 0, 0, 0] }, 
                    { text: `\n DecisionVue: Climate Impacts`, alignment: 'left', margin: [0, 0, 0, 5], color: "#ac04c2", lineHeight: 0.5 }
                ],
                [
                    { text: projectName, alignment: 'right', fontSize: projectName.length > 10 ? 10 : 17, color: "#FF5751",  margin: [0, 0, 0, 0] },
                    { text: `\n ${projectSubHeading}`, alignment: 'right', fontSize: 12, color: "#FF5751", margin: [0, 0, 0, 0], lineHeight: 0.5 }
                ],
                
              ],
              margin: [40, 20]
            }
        ],
        footer: (currentPage, pageCount) => [
            {
              columns: [
                [
                    { text: projectName, alignment: 'left', style: 'footerText'},
                    { text: `${projectNumber}`, alignment: 'left', style: 'footerText' },
                    { text: assetType, alignment: 'left', style: 'footerText' },
                    { text: `${climateLayersScenario} ${climateLayersTimeframe}`, alignment: 'left', style: 'footerText' }

                ],
                [
                    { text: 'WSP', alignment: 'right', style: 'footerText' },
                    { text: `${month}, ${year}`, alignment: 'right', style: 'footerText' },
                    { text: `Page ${currentPage}`, alignment: 'right', style: 'footerText' }

                ]
                
              ],
              margin: [40, 25]
            }
        ],
        pageMargins: [ 40, 80, 40, 80 ],
        styles: {
            header: { fontSize: 15, bold: true, color: "#FF5751", margin: [0, 10, 0, 10] },
            subheader: { fontSize: 12, bold: true, color: "#FF5751", margin: [0, 10, 0, 5] },
            metaDataHeader: { fontSize: 10, color: "#FF4B4B", margin: [0, 15, 0, 2] },
            metaDataText: { fontSize: 9, margin: [0, 3, 0, 2] },
            footerText: { fontSize: 7, color: "#FF4B4B", lineHeight: 1 }
        }
    
    };

    var docDefinition2 = {
        pageSize: pageSize,
        pageOrientation: 'landscape',
        content: [
            { text: 'Summary Risk Table', style: 'subheader'},
            {
                text: 'Table 4-3.1 Summary Risk Table',
                color: '#ff0000', style: 'metaDataText'
            },
            {
                table: {
                    headerRows: 1,
                    widths: tableBodyWithoutIDF[0].map(() => 'auto'), 
                    body: tableBodyWithoutIDF
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex % 2 !== 0) ? '#f3f3f3' : null; 
                    },
                    hLineWidth: function (i, node) {
                        return (i === 1) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 1) ? 1 : 0;
                    },
                    hLineColor: function (i, node) {
                        return '#000'; 
                    },
                    vLineColor: function (i, node) {
                        return '#000'; 
                    },
                    paddingLeft: function(i, node) { 
                        return (i === 0) ? 0 : 4; 
                    }, 
                    paddingRight: function(i, node) { 
                        return (i === node.table.widths.length) ? 0 : 4; 
                    },
                },
                pageBreak: 'after'
            }, 
            ...(idf.length > 0 ? [
                {
                    text: 'Table 4-3.2 Summary Risk Table for IDF Climate Parameters',
                    color: '#ff0000', style: 'metaDataText'
                },
                {
                    table: {
                        headerRows: 1,
                        widths: tableBodyWithIDF[0].map(() => 'auto'), 
                        body: tableBodyWithIDF
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex % 2 !== 0) ? '#f3f3f3' : null; 
                        },
                        hLineWidth: function (i, node) {
                            return (i === 1) ? 1 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 1) ? 1 : 0;
                        },
                        hLineColor: function (i, node) {
                            return '#000'; 
                        },
                        vLineColor: function (i, node) {
                            return '#000'; 
                        },
                        paddingLeft: function(i, node) { 
                            return (i === 0) ? 0 : 4; 
                        }, 
                        paddingRight: function(i, node) { 
                            return (i === node.table.widths.length) ? 0 : 4; 
                        },
                    }
                }
            ] : []),


            //Appendix A 
            {text: '', pageOrientation: 'landscape', pageBreak: 'before'},
            {text: 'Appendix A', style: 'header'},
            {text: 'Site Metadata', style: 'subheader'},
            {
                table: { 
                    //widths:'*',
                    body: tableBodyAppendixA
                },
                pageBreak: 'after'
            },
            //Appendix B
            {text: 'Appendix B', style: 'header'},
            {text: 'Detail Risk Scores', style: 'subheader'},
            ...appendixBTables,
            {text: '', pageBreak: 'after'},

            //Appendix C
            {text: 'Appendix C', style: 'header'},
            {text: 'Climate Probability Table', style: 'subheader'},
            {
                table: { 
                    //widths: ['20%', '40%', '8%', '8%', '8%', '8%', '8%'],
                    body: tables.appendixC_ClimateRiskScore,
                },
                pageBreak: 'after'
            },
            {text: 'Climate Parameter Definitions', style: 'metaDataText'},
            {
                table: { 
                    //widths: ['30%','20%', '50%'],
                    body: tables.appendixC_ClimateParameterDefinitions,
                },
                pageBreak: 'after'
            },

            //Appendix D
            {text: 'Appendix D', style: 'header'},
            {text: 'Severity of Impact Matrix', style: 'subheader' },
            {
                table: { 
                    body: appendixD,
                },
                pageBreak: 'after',
            },
        ],
    
        header: (currentPage, pageCount) => [
            {
              columns: [
                [
                  { image: wsp_logo, width: 50, margin: [0, 0, 0, 0] }, 
                  { text: `\n DecisionVue: Climate Impacts`, alignment: 'left', margin: [0, 0, 0, 5], color: "#ac04c2", lineHeight: 0.5 }
                ],
                [
                  { text: projectName, alignment: 'right', fontSize: 17, color: "#FF5751",  margin: [0, 0, 0, 0] },
                  { text: `\n ${projectSubHeading}`, alignment: 'right', fontSize: 12, color: "#FF5751", margin: [0, 0, 0, 0], lineHeight: 0.5 }
                ],
              ],
              margin: [40, 20]
            }
        ],
        footer: (currentPage, pageCount) => [
            {
              columns: [
                [
                    { text: projectName, alignment: 'left', style: 'footerText'},
                    { text: `${projectNumber}`, alignment: 'left', style: 'footerText' },
                    { text: assetType, alignment: 'left', style: 'footerText' },
                    { text: `${climateLayersScenario} ${climateLayersTimeframe}`, alignment: 'left', style: 'footerText' }
                ],
                [
                    { text: 'WSP', alignment: 'right', style: 'footerText' },
                    { text: `${month}, ${year}`, alignment: 'right', style: 'footerText' },
                    { text: `Page ${currentPage}`, alignment: 'right', style: 'footerText' }
                ]
              ],
              margin: [40, 25]
            }
        ],
        pageMargins: [ 40, 80, 40, 80 ],
        styles: {
            header: { fontSize: 15, bold: true, color: "#FF5751", margin: [0, 10, 0, 10] },
            subheader: { fontSize: 12, bold: true, color: "#FF5751", margin: [0, 10, 0, 5] },
            metaDataHeader: { fontSize: 10, color: "#FF4B4B", margin: [0, 15, 0, 2] },
            metaDataText: { fontSize: 9, margin: [0, 3, 0, 2] },
            footerText: { fontSize: 7, color: "#FF4B4B", lineHeight: 1 }
        }
    };
    
    pdfMake.createPdf(docDefinition, null, null, pdfFonts.pdfMake.vfs).download('analysis_results.pdf');
    pdfMake.createPdf(docDefinition2, null, null, pdfFonts.pdfMake.vfs).download('analysis_results_Summary-Risk.pdf'); 

};