import { UserManager } from 'oidc-client-ts';

const redirectTypeParamName = 'RType';
const Redirects = {
  standard: 'r',
  silent: 's',
  logout: 'l',
};

class AuthenticationService {

  constructor() {
    let settings = this.getOidcClientSettings();
    this.userManager = new UserManager(settings);
  }

  getOidcClientSettings() {
    const baseRedirectUrl = window.location.origin + '/?' + redirectTypeParamName + '=';
    return {
      authority: 'https://auth.woodweather.net',
      client_id: 'im-criva',
      redirect_uri: baseRedirectUrl + Redirects.standard,
      silent_redirect_uri: baseRedirectUrl + Redirects.silent,
      post_logout_redirect_uri: baseRedirectUrl + Redirects.logout,
      response_type: "code",
      scope: "openid profile api offline_access",
      filterProtocolClaims: true,
      automaticSilentRenew: true,
      loadUserInfo: true
    };
  }

  init() {
    const events = this.userManager.events;
    let self = this;
    events.addAccessTokenExpired(function() {
      self.manualTokenRefresh();
    });
    events.addSilentRenewError(this.redirectToLogin);

    let queryStringParams = new URLSearchParams(window.location.search);
    const redirectType = queryStringParams.get(redirectTypeParamName);

    if (redirectType === Redirects.standard) {
      return this.userManager.signinRedirectCallback()
        .catch(function (error) {
          console.log(error); // eslint-disable-line no-console
          window.location.href = '/login';
        }).then(function() {
          // we need to refresh login page on redirect since page loads before user authorization info is stored
          window.location.href = '/login';
        });
    } else if (redirectType === Redirects.silent) {
      return this.userManager.signinSilentCallback().catch(function (err) {
        console.log(err); // eslint-disable-line no-console
      });
    }
  }

  getUser() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager.getUser().then(function (user) {
        if (user == null) {
          return resolve(null);
        } else{
          return resolve(user);
        }
      }).catch(function (err) {
        self.redirectToLogin(err);
        return reject(err)
      });
    })
  }

  getProfile() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager.getUser().then(function (user) {
        if (user == null) {
          self.redirectToLogin('Error fetching user profile token. Null value for user.');
          return resolve(null)
        } else{
          return resolve(user.profile)
        }
      }).catch(function (err) {
        self.redirectToLogin(err);
        return reject(err)
      });
    })
  }

  getAccessToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager.getUser().then(function (user) {
        if (user == null) {
            self.redirectToLogin("Seems like you may need to login again. No access token found.");
            return resolve(null);
        } else{
          return resolve(user.access_token)
        }
      }).catch(function (err) {
        self.redirectToLogin(err);
        return reject(err)
      });
    })
  }

  manualTokenRefresh() {
    let self = this;
    try {
      this.userManager.signinSilent().then(function (user) { // we try to renew token manually if it can't be done automatically
        if (user == null) {
            self.redirectToLogin('Error manually refreshing token. Null value for user.');
        }
      }).catch(function (err) {
        self.redirectToLogin(err);
      });
    } catch(e) {
      console.log(e); // eslint-disable-line no-console
    }
  }

  redirectToLogin(error) {
    console.log(error); // eslint-disable-line no-console
    // redirecting user to login screen
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = '/login';
  }

  login() {
    return this.userManager.signinRedirect();
  }

  logout() {
    var events = this.userManager.events;
    events.removeSilentRenewError(this.redirectToLogin);
    window.localStorage.clear(); // eslint-disable-line no-undef

    this.userManager.signoutRedirect().catch(function (err) {
      console.log(err) // eslint-disable-line no-console
    })
  }
}

const authService = new AuthenticationService();
export default authService;