import { Outlet, Navigate } from "react-router-dom";
import React, { useContext } from 'react';
import { AuthContext } from './context/authContext';

const UnProtectedRoutes = () => {
    const { userAuthenticated } = useContext(AuthContext);

    return (
        userAuthenticated ? <Navigate to="/" />  : <Outlet/>
    )
}

export default UnProtectedRoutes