import React from 'react';
import { Typography } from '@mui/material';
import legends from './legendMap';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Fade from '@mui/material/Fade';

const legend = {
    position: 'absolute',
    bottom: '3%',
    right: '2%',
    zIndex: '1000',
    color: '#233845',
    fontSize: '12px',
    fontWeight: 700
}

const legend_title = {
    color: '#fff'
}

const info_button = {
    transition: 'all .2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.3)'
    }
}

const opacity_container = {
    display: 'flex',
    flexDirection: 'column'
}

const opactity_title = {
    padding: 0,
    color: '#fff',
    lineHeight: 1.5
}

const opacity_slider = {
    width: '280px'
}

const info_modal_container = {
    width: '450px',
    height: 'fit-content',
    padding: '5px 0',
    borderRadius: '10px',
    backgroundColor: '#fff'
}

const info_modal_title = {
    padding: '5px 3px 7px 9px',
    fontWeight: 'bold'
}

const info_modal_item_title = {
    paddingLeft: '10px',
    fontWeight: 'bold',
    lineHeight: 1.5
}

const info_modal_item_value = {
    lineHeight: 1.5,
    paddingLeft: '10px',
    fontSize: 10
}

const layer_description_container = {
    display: 'flex',
    flexDirection: 'column'
}

const info_desc_modal_item_value = {
    lineHeight: 1.5,
    paddingLeft: '10px',
    marginTop: '10px',
    fontSize: 10
}

const ClimateLayerLegend = (props) => {
    const [showInfoModal, upadteInfoShowing] = React.useState(false);
    const climateLayer = props.climateLayer;

    if (!legends[climateLayer.legend_id]) {
        return (<></>);
    }

    const legendInfo = legends[climateLayer.legend_id];
    const no_units = legendInfo.units == "" ? true : false;
    const colors = no_units ? legendInfo.colorArray : [legendInfo.colorArray[0]].concat(legendInfo.colorArray); //concating colors array with first color to support units when there is units
    const layerDescription = climateLayer.description ? climateLayer.description.split(/\r?\n/).filter(description => description) : [];
    const legend_container = {
        background: `linear-gradient(to right, ${colors.join()})`,
        opacity: '0.8',
        textShadow: '2px 2px 4px #000',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: '1px',
        borderRadius: '20px',
        whiteSpace: 'nowrap',
        padding: '2px',
        paddingLeft: '10px',
        width: '100%',
    }

    const item_width = no_units ? 100 / legendInfo.thresholdArray.length : 100 / (legendInfo.thresholdArray.length + 1); //adding one for units
    const legend_item = {
        width: `${item_width}%`,
        display: 'inline-block',
        textAlign: 'center',
        verticalAlign: 'middle',
        color: 'rgb(255,255,255)'
    }

    function handleOpacityChange(event, opacity) {
        props.onOpacityChange(opacity);
    }

    function toggleInformationModal() {
        upadteInfoShowing((infoShowing) => !infoShowing);
    }

    return (
        <div style={legend}>
            <Fade in={showInfoModal}>
                <div style={info_modal_container}>
                    <Typography sx={info_modal_title} variant='subtitle2'>{climateLayer.title}</Typography>
                    <div>
                        <Typography sx={info_modal_item_title} variant='overline'>Data source: </Typography>
                        <Typography sx={info_modal_item_value} variant='overline'>{climateLayer.source}</Typography>
                    </div>
                    <div>
                        <Typography sx={info_modal_item_title} variant='overline'>Scenario: </Typography>
                        <Typography sx={info_modal_item_value} variant='overline'>{climateLayer.scenario}</Typography>
                    </div>
                    <div>
                        <Typography sx={info_modal_item_title} variant='overline'>Timeframe: </Typography>
                        <Typography sx={info_modal_item_value} variant='overline'>{climateLayer.timeframe}</Typography>
                    </div>
                    <div>
                        <Typography sx={info_modal_item_title} variant='overline'>Parameter: </Typography>
                        <Typography sx={info_modal_item_value} variant='overline'>{climateLayer.data_parameter}</Typography>
                    </div>
                    <div style={layer_description_container}>
                        {layerDescription.length > 0 ? <Typography sx={info_modal_item_title} variant='overline'>Description: </Typography> : null }
                        {layerDescription.map(description => {
                            return (
                                <Typography sx={info_desc_modal_item_value} variant='overline'>{description.replace("\\n"," ")}</Typography>
                            )
                        })}
                    </div>
                </div>
            </Fade>

            <div>
                <Typography sx={legend_title} variant='overline'>{climateLayer.title}</Typography>
                <IconButton sx={info_button} onClick={toggleInformationModal} title="Climate layer information"><InfoIcon sx={{ color: "#fff"}} /></IconButton>
            </div>
            <div style={opacity_container}>
                <Typography sx={opactity_title} variant='caption'>Opacity:</Typography>
                <Slider defaultValue={0.8} sx={opacity_slider} onChange={handleOpacityChange} step={0.1} min={0} max={1} aria-label="Default" valueLabelDisplay="auto" />
            </div>
            <div style={legend_container}>
                {!no_units && (
                    <span key={'units'} style={legend_item}>{legendInfo.units}</span>
                )}
                {legendInfo.thresholdArray.map((threshold) => (
                    <span key={threshold} style={legend_item}>{threshold}</span>
                ) )}
            </div>

        </div>

    )
}

export default ClimateLayerLegend;