import React from 'react';
import {animateScroll as scroll } from 'react-scroll';
import { FooterContainer, 
        FooterWrap, 
        FooterLinksContainer, 
        FooterLinksWrapper, 
        FooterLinkItems, 
        FooterLinkTitle, 
        FooterLink,
        SocialMedia,
        SocialMediaWrap,
        WebsiteRights,
        FooterLinkExternal,
     } 
        from './FooterElements';

const Footer = () => {

    //function that scrolls to top of page
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const privacyPolicyLink = "https://www.wsp.com/en-us/legal/privacy-policy";

    return (
        <FooterContainer id='footer'>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                                <FooterLinkExternal href={privacyPolicyLink}>Privacy Notice</FooterLinkExternal>
                        </FooterLinkItems>
                        <FooterLinkItems>
                                <FooterLink to="/">Terms of Service</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                                <FooterLink to="/">About</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                                <FooterLink to="/">Contact Us</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <WebsiteRights>WSP © {new Date().getFullYear()} All rights reserved</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
            
        </FooterContainer>
    )
}

export default Footer
