const legends = {
    'daily_freeze_thaw': {
        'colorArray': [
            '#2c7bb6',
            '#6eacd1',
            '#aedbe7',
            '#daeed1',
            '#fff9b7',
            '#fece87',
            '#f89c59',
            '#e65538',
            '#d7191c'
          ],
          'thresholdArray': [
            0,
            13,
            26,
            39,
            52,
            65,
            78,
            90,
            100
          ],
          'units': 'cycles'
    },
    'one_day_max_precip': {
      'colorArray': [
          '#f0f9e8',
          '#c5e8c5',
          '#94d6c1',
          '#65bbc6',
          '#3796c4',
          '#0868ac'
        ],
        'thresholdArray': [
          15,
          28,
          41,
          54,
          67,
          80
        ],
        'units': 'mm'
    },
    'five_day_max_precip': {
      'colorArray': [
        "#f0f9e8",
        "#ccebcb",
        "#a5dcbf",
        "#7bccc4",
        "#56b0c8",
        "#2f8fc0",
        "#0868ac"
      ],
      'thresholdArray': [
        15,
        45,
        76,
        107,
        138,
        170,
        200
      ],
      'units': 'mm'
    },
    'storm_surge_residual': {
      'colorArray': [
        "#2b83ba",
        "#abdda4",
        "#ffffbf",
        "#fdae61",
        "#d7191c"
      ],
      'thresholdArray': [
        0.5,
        1,
        1.5,
        2,
        2.5
      ],
      'units': 'm'
    },
    'storm_surge_twl': {
      'colorArray': [
        "#2b83ba",
        "#abdda4",
        "#ffffbf",
        "#fdae61",
        "#d7191c"
      ],
      'thresholdArray': [
        0,
        2,
        4,
        6,
        8
      ],
      'units': 'm'
    },
    'days_tmin_less_than_m25': {
      'colorArray': [
          '#f7fbff',
          '#deebf7',
          '#c6dbef',
          '#9ecae1',
          '#6baed6',
          '#4292c6',
          '#2171b5',
          '#08519c',
          '#08306b'
        ],
        'thresholdArray': [
          0,
          19.5,
          39,
          58.5,
          78,
          97.5,
          117,
          135,
          150
        ],
        'units': '# days'
    },
    'days_tmax_greater_than_30': {
      'colorArray': [
        "#fff5f0",
        "#fee0d2",
        "#fcbba1",
        "#fc9272",
        "#fb6a4a",
        "#ef3b2c",
        "#cb181d",
        "#a50f15",
        "#67000d"
        ],
        'thresholdArray': [
          0,
          6.5,
          13,
          19.5,
          26,
          32.5,
          39,
          45,
          50
        ],
        'units': '# days'
    },
    'heat_wave': {
      'colorArray': [
        "#ffffb2",
        "#fecc5c",
        "#fd8d3c",
        "#f03b20",
        "#bd0026"
        ],
        'thresholdArray': [
          0,
          25,
          50,
          75,
          100
        ],
        'units': '# waves'
    },
    'snow_depth': {
      'colorArray': [
        "#f7fbff",
        "#deebf7",
        "#c6dbef",
        "#9ecae1",
        "#6baed6",
        "#4292c6",
        "#2171b5",
        "#08519c",
        "#08306b"
        ],
        'thresholdArray': [
          0,
          2.6,
          5.2,
          7.8,
          10.4,
          13,
          15.5,
          18,
          20
        ],
        'units': 'cm'
    },
    'floodplain': {
      'colorArray': [
        "rgba(255,255,255,0.1)",
        "#deebf7",
        "#c6dbef",
        "#9ecae1",
        "#6baed6",
        "#4292c6",
        "#2171b5",
        "#08519c",
        "#08306b"
      ],
      'thresholdArray': [
        0,
        1.5,
        3.3,
        5.25,
        7.2,
        9,
        11,
        13,
        15
      ],
      'units': 'm'
    },
    'coastal_sensitivity_2000': {
      'colorArray': [
        "#6087fc",
        "#088c85",
        "#67ce6d",
        "#fbc03a",
        "#ef5153"
      ],
      'thresholdArray': [
        '<= --500',
        '--499 : --150',
        '--149 : 150',
        '150 : 500',
        '> 500'
      ],
      'units': ''
    },
    'coastal_sensitivity_2090': {
      'colorArray': [
        "#6087fc",
        "#088c85",
        "#67ce6d",
        "#fbc03a",
        "#ef5153"
      ],
      'thresholdArray': [
        '<= --500',
        '--499 : --150',
        '--149 : 150',
        '150 : 500',
        '>500'
      ],
      'units': ''
    },
    'sealevelchange_2006_2020': {
      'colorArray': [
        "#7fa4f9",
        "#6682fe",
        "#f9ff4b",
        "#ff8132",
        "#ff0307"
      ],
      'thresholdArray': [
        '< 0.2',
        '0.2 : 0.4',
        '0.4 : 0.6',  
        '0.6 : 0.8',
        '> 0.8'
      ],
      'units': 'm'
    },
    'sealevelchange_2006_2099': {
      'colorArray': [
        "#7fa4f9",
        "#6682fe",
        "#f9ff4b",
        "#ff8132",
        "#ff0307"
      ],
      'thresholdArray': [
        '< 0.2',
        '0.2 : 0.4',
        '0.4 : 0.6',  
        '0.6 : 0.8',
        '> 0.8'
      ],
      'units': 'm'
    },
    'cancoast_waveheight_1996_2005': {
      'colorArray': [
        "#6087fc",
        "#359e7d",
        "#8dc742",
        "#e4b337",
        "#af5c4e"
      ],
      'thresholdArray': [
        '< 0.25',
        '0.26 : 0.75',
        '0.76 : 1.50',
        '1.51 : 2.25',
        '> 2.25'
      ],
      'units': 'm'
    },
    'cancoast_waveheight_2090_2099': {
      'colorArray': [
        "#6087fc",
        "#359e7d",
        "#8dc742",
        "#e4b337",
        "#af5c4e"
      ],
      'thresholdArray': [
        '< 0.25',
        '0.26 : 0.75',
        '0.76 : 1.50',
        '1.51 : 2.25',
        '> 2.25'
      ],
      'units': 'm'
    },
    'climate_moisture': {
      'colorArray': [
        "#a6611a",
        "#c3924b",
        "#dfc27d",
        "#eadcb9",
        "#f5f5f5",
        "#bbe1db",
        "#80cdc1",
        "#41a999",
        "#018571"
      ],
      'thresholdArray': [
        '--80',
        '--60',
        '--40',
        '--20',
        0,
        20,
        40,
        60,
        80
      ],
      'units': ''
    },
    'lightning_days': {
      'colorArray': [
        "#2c7bb6",
        "#abd9e9",
        "#ffffbf",
        "#fdae61",
        "#d7191c"
      ],
      'thresholdArray': [
        0,
        6,
        12,
        18,
        25
      ],
      'units': '# days'

    },
    'lightning_density': {
      'colorArray': [
        "#2c7bb6",
        "#abd9e9",
        "#ffffbf",
        "#fdae61",
        "#d7191c"
      ],
      'thresholdArray': [
        0,
        0.25,
        0.5,
        0.75,
        1
      ],
      'units': 'flashes/km^2/year'
    },
    'average_tmax': {
      'colorArray': [
        "#2c7bb6",
        "#abd9e9",
        "#ffffbf",
        "#fdae61",
        "#d7191c"
      ],
      'thresholdArray': [
        -10,
        -5,
        0,
        5,
        10
      ],
      'units': '°C'
    },
    'average_tmin': {
      'colorArray': [
        "#2c7bb6",
        "#abd9e9",
        "#ffffbf",
        "#fdae61",
        "#d7191c"
      ],
      'thresholdArray': [
        -10,
        -5,
        0,
        5,
        10
      ],
      'units': '°C'
    },
    'average_snow_depth': {
      'colorArray': [
        "rgba(255,255,255,0.8)",
        "#deebf7",
        "#9ecae1",
        "#6baed6",
        "#4292c6",
        "#2171b5",
        "#08519c",
        "#08306b"
      ],
      'thresholdArray': [
        0,
        2.5,
        5,
        7.5,
        10,
        12.5,
        15,
        17.5,
        20
      ],
      'units': 'cm'
    },
    'tornadoes': {
      'colorArray': [
        "#2c7bb6",
        "#c7e6db",
        "#fec980",
        "#d7191c"
      ],
      'thresholdArray': [
        0,
        7,
        14,
        21
      ],
      'units': '# tornadoes'
    }
}

export default legends;