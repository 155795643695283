import React, { useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { tree_node, tree_chevron, tree_row_flex } from './styles';
import { Typography } from '@mui/material';



const TreeComponent = (props) => {

    const [checkedNodes, updateCheckedNodes] = React.useState([]);
    const [expandedNodes, updateExpandedNodes] = React.useState([]);
    const treeType = props.type ? props.type : "checkbox";

    useEffect(() => {
        if (props.updateSelectedNodes) {
            props.updateSelectedNodes(checkedNodes);
        }
    }, [checkedNodes]);

    function updateActiveNodes(node) {

        const nodeIndex = checkedNodes.findIndex(treeNode => treeNode.id == node.id && treeNode.hierarchy == node.hierarchy);

        if (nodeIndex == -1) {
            //update and add children as well
            const children = node.children ? node.children : [];

            if (children.length > 0) {
                // traverse tree
                const allActiveNodes = [{'id': node.id, 'hierarchy': node.hierarchy}];
                const nodeStack = [node];
                while (nodeStack.length > 0) {
                    const currentNode = nodeStack.pop();
                    const currentNodeChildren = currentNode.children ? currentNode.children : [];
                    for (let i = 0; i < currentNodeChildren.length; i++) {
                        // check child node is aready active
                        const childNodeIndex = checkedNodes.findIndex(treeNode => treeNode.id == currentNodeChildren[i].id && treeNode.hierarchy == currentNodeChildren[i].hierarchy);
                        if (childNodeIndex == -1) {
                            allActiveNodes.push({'id': currentNodeChildren[i].id, 'hierarchy': currentNodeChildren[i].hierarchy });
                        }
                        nodeStack.push(currentNodeChildren[i]);
                    }
                }

                const arrCopy = [...checkedNodes];
                const concatArr = arrCopy.concat(allActiveNodes);
                updateCheckedNodes(concatArr);
            } else {
                if (treeType == "radioCheckbox") {
                    updateCheckedNodes([{'id': node.id, 'hierarchy': node.hierarchy}]);
                } else {
                    updateCheckedNodes([...checkedNodes, {'id': node.id, 'hierarchy': node.hierarchy}]);
                }
            }

        } else {
            const arrCopy = [...checkedNodes];

            const children = node.children ? node.children : [];
            if (children.length > 0) {
                // traverse tree
                const nodeStack = [node];
                while (nodeStack.length > 0) {
                    const currentNode = nodeStack.pop();
                    const currentNodeIndex = arrCopy.findIndex(treeNode => treeNode.id == currentNode.id && treeNode.hierarchy == currentNode.hierarchy);

                    if (currentNodeIndex != -1) { arrCopy.splice(currentNodeIndex, 1); }

                    const currentNodeChildren = currentNode.children ? currentNode.children : [];
                    for (let i = 0; i < currentNodeChildren.length; i++) {
                        nodeStack.push(currentNodeChildren[i]);
                    }
                }

                updateCheckedNodes(arrCopy);

            } else {
                arrCopy.splice(nodeIndex, 1);
                updateCheckedNodes(arrCopy);
            }
        }

    }

    const fetchTreeActiveBranchStatus = (node) => {
        let numberOfChildrenActive = 0;
        let numberOfChildren = 0;

        // traverse tree
        const nodeStack = [node];
        while (nodeStack.length > 0) {
            const currentNode = nodeStack.pop();

            const currentNodeChildren = currentNode.children ? currentNode.children : [];
            for (let i = 0; i < currentNodeChildren.length; i++) {
                numberOfChildren++;
                // check if child node is active
                const activeChildNodeIndex = checkedNodes.findIndex(treeNode => treeNode.id == currentNodeChildren[i].id && treeNode.hierarchy == currentNodeChildren[i].hierarchy);
                if (activeChildNodeIndex != -1) {
                    numberOfChildrenActive++;
                }
                nodeStack.push(currentNodeChildren[i]);
            }
        }

        return [numberOfChildren, numberOfChildrenActive];
    }

    const isNodeActive = (node) => {
        const currentNodeSelected = checkedNodes.findIndex(treeNode => treeNode.id == node.id && treeNode.hierarchy == node.hierarchy) != -1 ? true : false;
        const nodeBranchesStatus = fetchTreeActiveBranchStatus(node);
        const hasChildrenActive = nodeBranchesStatus[1] > 0 ? true : false;

        if (currentNodeSelected || hasChildrenActive) {
            return true;
        } else {
            return false;
        }
    }

    const isInterdeterminate = (node) => {

        const nodeBranchesStatus = fetchTreeActiveBranchStatus(node);

        if (nodeBranchesStatus[1] > 0 ) {
            return nodeBranchesStatus[0] == nodeBranchesStatus[1] ? false : true;
        } else {
            return false;
        }

    }

    const Tree = (props) => {
        const data = props.data;
        return (
                data.map((node) => (
                    <div key={node.id} style={{display: "flex", flexDirection: "column", marginLeft: node.root ? '0px' : '24px'}}>
                        <TreeNode node={node} />
                    </div>
                ))
        )

    }

    const TreeNode = (props) => {
        const node = props.node;
        const hasChildren = node.children ? true : false;
        const nodeExpanded = expandedNodes.findIndex((expandedNode) => expandedNode.id == node.id && expandedNode.hierarchy == node.hierarchy) != -1 ? true : false;

        const toggleChildNodes = () => {
            //updatedVisibleChildren((previousState) => !previousState);
            if (nodeExpanded) {
                const nodeIndex = expandedNodes.findIndex((expandedNode) => expandedNode.id == node.id && expandedNode.hierarchy == node.hierarchy);
                const arrCopy = [...expandedNodes];
                arrCopy.splice(nodeIndex, 1);
                updateExpandedNodes(arrCopy);

            } else {
                updateExpandedNodes([...expandedNodes, {'id': node.id, hierarchy: node.hierarchy }]);
            }
        }

        return (
            <div key={node.id}>
                <div style={tree_row_flex}>
                    <IconButton aria-label="childnode" onClick={() => toggleChildNodes() } disabled={hasChildren ? false : true} sx={{ padding: '0px', 'opacity': hasChildren ? 1 : 0 }} size="small">
                         { nodeExpanded ? <ExpandMoreIcon sx={[tree_chevron]} /> : <ChevronRightIcon sx={[tree_chevron]} /> }
                    </IconButton>
                    {
                        treeType == "radioCheckbox" && !hasChildren || treeType == "checkbox" ?
                            <FormControlLabel
                                sx={tree_node}
                                label={node.name}
                                control={<Checkbox checked={isNodeActive(node)} indeterminate={isInterdeterminate(node)} onChange={() => updateActiveNodes(node)} />}
                            />
                        : <Typography variant="body1" sx={tree_node}>{node.name}</Typography>
                    }
                </div>
                { hasChildren && nodeExpanded && (
                    <Tree data={node.children} />
                )}
            </div>
        )
    }

    return (
        <Tree data={props.data} />
    )



}

export default TreeComponent;