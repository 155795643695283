const custom_table_headers = { // used to add custom column names mainly for impact severity review table
    'annual_1_day_maximum_precipitation': 'Annual 1 Day Max Precip',
    'warm_season_1_day_maximum_precipitation': 'Warm Season 1 Day Max Precip',
    'cold_season_1_day_maximum_precipitation': 'Cold Season 1 Day Max Precip',
    'hundred_year_flooding': '100y Flooding',
    'one_hundred_year_flood': '100y Flooding',
    'days_greater_30_c': 'Days > 30°C',
    'days_less_negative_25_c': 'Days < -25°C',
    'heat_waves_3plus_days_greater_30c': 'Heat Waves (3 days > 30°C)',
    'temp_days_greater_than_30': 'Temp. # Days > 30°C',
    'temp_days_less_than_25': 'Temp. # Days < -25°C'
}

function camelCaseToSentenceCase(s) {
    if (s == null) {
      return;
    }
    const result = s.replace(/([A-Z])/g, "$1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

function capitalizeAllWordsInString(str) {

    const words = str.split(' ');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);  
    }
    return words.join(' ');
}

function formatStringValue(value) {
    if (typeof value === 'string') {
        const camelCase = camelCaseToSentenceCase(value);
        const formatedValue = camelCase.replaceAll('_', ' ');
        // capitalizing each word in string
        const capitalizedValue = capitalizeAllWordsInString(formatedValue);
        return capitalizedValue;
    } else {
        return value;
    }

}

function formatCellValue(value) {
    if (typeof value === 'string') {
        const camelCase = camelCaseToSentenceCase(value);
        const formatedValue = camelCase.replaceAll('_', ' ');
        return formatedValue;
    } else {
        return value;
    }
}

export const tableHeaderImpactSeverity = (data, definition=[], headerStyle) => {
    const tableHeaderKeys = Object.keys(data[0]);
    const filteredOutId = tableHeaderKeys.filter((headerKey) => headerKey != 'id'); // removing id column from table
    const header = filteredOutId.map((header, index) => {
            const definitionIndex = definition.length > 0 ? definition.findIndex((property) => property.column_name == header) : null;
            const has_custom_table_heading = custom_table_headers.hasOwnProperty(header) ? true : false;
            const formatedHeader = has_custom_table_heading ? custom_table_headers[header] : definitionIndex ? formatStringValue(definition[definitionIndex].column_alias) : formatStringValue(header);
            return formatedHeader ;
        });    
    return header ;
} 
export const tableRowsImpactSeverity = (data, definition = [], rowStyle) => {
    const tableHeaderKeys = Object.keys(data[0]);
    const filteredOutId = tableHeaderKeys.filter((headerKey) => headerKey !== 'id'); // remove 'id' column
    const sortedDataRows = data.sort((a, b) => (a.id > b.id) ? 1 : -1); // sort data rows by id

    const rows = sortedDataRows.map((row) => {
        return filteredOutId.map((column) => {
            const cellValue = formatCellValue(row[column]); // Format the cell value as needed
            return cellValue;
        });
    });

    return rows; // Returns an array of arrays for pdfmake
};

//For Appendix B
function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

function isNumber(n) {
    return typeof n === 'number' && !isNaN(n);
}


function parseIDFHeader(data_parameter) {
    const parameter_parts = data_parameter.split("_");
    const idf_time = parseFloat(parameter_parts[1]);
    const idf_year = parseFloat(parameter_parts[2]);
    return "IDF " + idf_year + " year " + idf_time + " min";
}

function sortDetailsColumnKeys(tableColumnKeys) {
    const sortedKeys = tableColumnKeys.sort((key1, key2) => {
        const formatedKey1 = parseColumnName(key1);
        const formatedKey2 = parseColumnName(key2);
        if (formatedKey1 < formatedKey2) {
            return -1;
        } else if (formatedKey1 > formatedKey2) {
            return 1;
        }
        // values must be equal to key
        return 0;
    });

    return sortedKeys
}

function formatRiskValue(value) {
    if (isFloat(value)) {
        return value.toFixed(2);
    } else {
        return value;
    }
}

function parseColumnName(header) {
    
    const customParameterMap = { // hardcoded this for now (basically mapping climate layer parameter property)
        "average_snow_depth": "Average Snow Depth (Annual) (cm)",
        "average_snow_depth_winter": "Average Snow Depth (Winter) (cm)",
        "average_tmax": "Average Tmax (%change)",
        "average_tmin": "Average Tmin (%change)",
        "max_precip_summer": "1 Day Max Precip (Summer) (mm)",
        "max_precip_spring": "1 Day Max Precip (Spring) (mm)",
        "max_precip_winter": "1 Day Max Precip (Winter) (mm)",
        "max_precip_annual": "1 Day Max Precip (Annual) (mm)",
        "max_precip_autumn": "1 Day Max Precip (Autumn) (mm)",
        "5_day_max_precip_annual": "5 Day Max Precip (Annual) (mm)",
        "tornadoes": "Tornadoes (#events per year)",
        "heat_wave": "Heat Waves (3 days > 30°C)",
        "lightning_density": "Lightning Density (flashes/km2/year)",
        "lightning": "Lightning (# days per year)",
        "days_greater_than_30_c": "Days > 30°C (# days per year)",
        "days_less_than_neg_25_c": "Days < -25°C (# days per year)",
        "storm_surge_residual": "Storm Surge (Residual) (m)",
        "storm_surge_twl": "Storm Surge (TWL) (m)",
        "mean_wave_height": "Mean Wave Height (m)",
        "freeze_thaw_cycles": "Freeze Thaw Cycles (# days per year)",
        "hundred_year_flood": "100y Flooding (m)",
        "coastal_sensitivity_index": "Coastal Sensitivity Index",
        "climate_moisture_index": "Climate Moisture Index",
        "max_value": "Max Value"
    }

    if (customParameterMap.hasOwnProperty(header)) {
        return customParameterMap[header];
    } else {
        return formatStringValue(header);
    }
}

const parseDetailRiskGridHeader = (columnKeys) => {

    // sorting columns in alphabetical order 
    const keysToFilter = ['property', 'max_value'];

    const placeholder_columns = columnKeys.filter((key) => key.includes('placeholder_')); // placeholder columns are blank empty columns
    const columnKeysToSort = columnKeys.filter((key) => !keysToFilter.includes(key) && !key.includes('placeholder_')); // we don't to sort blank placeholder columns and property and max value columns
    const sortedKeys = sortDetailsColumnKeys(columnKeysToSort);

    sortedKeys.unshift('property');
    placeholder_columns.forEach((key) => sortedKeys.unshift(key)); // adding placeholder columns back at respectively place
    // sortedKeys.push('max_value'); // adding max value column at the end

    const headers = sortedKeys.map((header) => {
        const formatedHeaderName = header.startsWith('idf') ? parseIDFHeader(header) : parseColumnName(header);
        return formatedHeaderName;
    });
    return headers;
}

const parseDetailRiskType2GridHeader = (columnKeys, metadataDefinitions) => {
    const asset_risk_properties = getAssetRiskProperties(metadataDefinitions);

    // sorting columns in alphabetical order 
    const keysToFilter = ['contaminant', 'medium', 'receptors', 'max_value'];
    const columnKeysToSort = columnKeys.filter((key) => !keysToFilter.includes(key)); // geting all the columns we want to be arranged in alphabetical order
    const sortedKeys = sortDetailsColumnKeys(columnKeysToSort);

    sortedKeys.unshift('contaminant', 'medium', 'receptors');
    // sortedKeys.push('max_value'); // adding max value column at the end

    const headers = sortedKeys.map((header) => {
        const formatedHeaderName = parseColumnName(header); 
        //const headerIsRiskProperty = asset_risk_properties.includes(header) ? true : false;
        return formatedHeaderName;
    });
    return headers;
}

function getAssetRiskProperties(metadataDefinitions) {
    const risk_column_names = [];
    metadataDefinitions.forEach((definition) => { // asset properties that are used in calculating risks
        if (definition.is_risk_property) {
            risk_column_names.push(definition.column_name);
        }
    });
    return risk_column_names; 
}

export const getDetailsTableColumns = (assetRisks, riskType, metadataDefinitions) => {
    const columnKeys = ['property'];
    if (riskType == 2) { // adding column placeholders to match up with risk type 2 second table
        const asset_risk_columns = getAssetRiskProperties(metadataDefinitions); // properties of asset that are used in risk calculation, only needed for riks type 2
        for (let i=0; i< asset_risk_columns.length-1; i++) {
            columnKeys.unshift('placeholder_' + i);
        }
    }
    assetRisks.forEach((risk) => columnKeys.push(risk.parameter)); // getting climate parameter names to use as column headers
    columnKeys.push('max_value');
    const formattedColumnKeys = parseDetailRiskGridHeader(columnKeys);
    return formattedColumnKeys;
};

export const getDetailsTableColumns2 = (assetRisks, metadataDefinitions) => { // For asset with risk type 2 (risk data with combinations)
    const columnKeys = getAssetRiskProperties(metadataDefinitions); // properties of asset that are used in risk calculation
    
    assetRisks.forEach((risk) => { // getting climate parameter names to use as column headers for all climate layers
        if (risk.probability != null && risk.parameter_value != null ) { //we don't want to show risk data for assets with no probability risk data
            columnKeys.push(risk.parameter);
        }
    }); 
    const formattedColumnKeys = parseDetailRiskType2GridHeader(columnKeys, metadataDefinitions);
    return formattedColumnKeys;
}

function appendClimateAndProbabilityRows(rows, assetRisk) { // generating row with climate layer value and probbility value
        
    const climate_parameter_header = assetRisk.parameter;
    // row showing climate layer value
    const parameter_value_row = {'property': 'climate value'};
    parameter_value_row[climate_parameter_header] = assetRisk.parameter_value == null ? 'N/A' : parseFloat(assetRisk.parameter_value);

    // row showing climate probability
    const probability_value_row = {'property': 'probability'};
    probability_value_row[climate_parameter_header] = (assetRisk.probability == null || assetRisk.parameter_value == null) ? 'N/A' : parseInt(assetRisk.probability);

    // row showing importance factor
    const importance_factor_row = {'property': 'importance factor'}; 
    importance_factor_row[climate_parameter_header] = (assetRisk.probability == null || assetRisk.parameter_value == null || assetRisk.importance_factor == null) ? 'N/A' : parseFloat(assetRisk.importance_factor);

    const initial_row = rows.length > 0 ? false : true;
    if (initial_row) {
        parameter_value_row['max_value'] = assetRisk.parameter_value == null ? 'N/A' : parseFloat(assetRisk.parameter_value);
        probability_value_row['max_value'] = (assetRisk.probability == null || assetRisk.parameter_value == null) ? 'N/A' : parseInt(assetRisk.probability);
        importance_factor_row['max_value'] = (assetRisk.probability == null || assetRisk.parameter_value == null || assetRisk.importance_factor == null) ? 'N/A' : parseFloat(assetRisk.importance_factor);
        rows.push(parameter_value_row);
        rows.push(probability_value_row);
        rows.push(importance_factor_row);
    } else {
        // getting max value for climaate value row
        const climate_value_row_index = rows.findIndex((details) => details.property == 'climate value');
        rows[climate_value_row_index] = { ...rows[climate_value_row_index], ...parameter_value_row};

        if (assetRisk.parameter_value != null) {
            if (rows[climate_value_row_index].max_value == 'N/A' || isNaN(rows[climate_value_row_index].max_value)) {
                rows[climate_value_row_index].max_value = parseFloat(assetRisk.parameter_value);
            } else if (rows[climate_value_row_index].max_value < parseFloat(assetRisk.parameter_value)) {
                rows[climate_value_row_index].max_value = parseFloat(assetRisk.parameter_value);
            }
        }

        // getting max value for probability row
        const probability_row_index = rows.findIndex((details) => details.property == 'probability');
        rows[probability_row_index] = { ...rows[probability_row_index], ...probability_value_row};

        if (assetRisk.parameter_value != null && assetRisk.probability != null) {
            if (rows[probability_row_index].max_value == 'N/A' || isNaN(rows[probability_row_index].max_value)) {
                rows[probability_row_index].max_value = parseInt(assetRisk.probability);
            } else if (rows[probability_row_index].max_value < parseInt(assetRisk.probability)) {
                rows[probability_row_index].max_value = parseInt(assetRisk.probability);
            }
        }

        // getting max value for importance factor row
        const importance_factor_row_index = rows.findIndex((details) => details.property == 'importance factor');
        rows[importance_factor_row_index] = { ...rows[importance_factor_row_index], ...importance_factor_row};

        if (assetRisk.parameter_value != null && assetRisk.probability != null && assetRisk.importance_factor != null) {
            if (rows[importance_factor_row_index].max_value == 'N/A' || isNaN(rows[importance_factor_row_index].max_value)) {
                rows[importance_factor_row_index].max_value = parseFloat(assetRisk.importance_factor);
            } else if (rows[importance_factor_row_index].max_value < parseFloat(assetRisk.importance_factor)) {
                rows[importance_factor_row_index].max_value = parseFloat(assetRisk.importance_factor);
            }
        }
        
    }

}

function appendImpactSeverityRows(currentRows, risk, metadataDefinitions) {

    const impact_severity_attributes = metadataDefinitions.filter((column) => column.is_risk_property); //getting attributes used in impact risk calculation
    impact_severity_attributes.forEach((severity_attribute) => {
        const severity_column_name = severity_attribute.column_name + '_severity';
        const severity_risk_column_name = severity_attribute.column_alias + '_risk';
        const initial_row = currentRows.findIndex((details) => details.property == severity_risk_column_name) == -1 ? true : false;
        const severity_risk_row = {'property': severity_risk_column_name};
        
        // calculated impact severity risk
        const total_attribute_risk = Math.round(parseFloat(risk[severity_column_name]) * parseInt(risk.probability) * parseFloat(risk.importance_factor));
        severity_risk_row[risk.parameter] = total_attribute_risk;

        if (initial_row) {
            severity_risk_row['max_value'] = total_attribute_risk;
            currentRows.push(severity_risk_row);
        } else {
            const severity_risk_row_index = currentRows.findIndex((details) => details.property == severity_risk_column_name);
            currentRows[severity_risk_row_index] = { ...currentRows[severity_risk_row_index], ...severity_risk_row };
            if (currentRows[severity_risk_row_index].max_value < total_attribute_risk) {
                currentRows[severity_risk_row_index].max_value = total_attribute_risk;
            }
        }
    });
}

function appendMaximumRisks(currentRows, risk) {
    const maximum_risk_row = { 'property': 'max_risk'}
    maximum_risk_row[risk.parameter] = parseInt(risk.max_risk);
    const initial_row = currentRows.findIndex((details) => details.property == 'max_risk') == -1 ? true : false;

    if (initial_row) {
        maximum_risk_row['max_value'] = parseInt(risk.max_risk);
        currentRows.push(maximum_risk_row);
    } else {
        const max_risk_row_index = currentRows.findIndex((details) => details.property == 'max_risk');
        currentRows[max_risk_row_index] = { ...currentRows[max_risk_row_index], ...maximum_risk_row};
        if (currentRows[max_risk_row_index].max_value < parseInt(risk.max_risk)) {
            currentRows[max_risk_row_index].max_value = parseInt(risk.max_risk);
        }
    }
}

function appendRiskCombinations(currentRows, asset_risk_properties, risk_combinations) {
    

    for (let i = 0; i < risk_combinations.length; i++) {
        
        const combination = risk_combinations[i];
        const climate_layer_parameter = combination.parameter;

        // Get row with the same risk combinations
        const combination_row_index = currentRows.findIndex((row) => { 
            let foundAssetProperties = 0;
            asset_risk_properties.forEach((risk_property) => {
                if (row[risk_property] == combination[risk_property]) {
                    foundAssetProperties = foundAssetProperties + 1;
                }
            });

            if (foundAssetProperties == asset_risk_properties.length){
                return true;
            } else {
                return false;
            }
        });

        // Append the asset risk according to the climate layer parameter
        if (combination_row_index != -1) {
            const current_combination_row = currentRows[combination_row_index];
            current_combination_row[climate_layer_parameter] = combination['risk'];
            if (combination['risk'] > current_combination_row['max_value']) { // max risk per combination row
                current_combination_row['max_value'] = combination['risk'];
            }
        } else { // risk combination not yet added to table rows
            const row_id = currentRows.length + 1;
            const row = {'id': row_id};
            asset_risk_properties.forEach((risk_property) => {
                row[risk_property] = combination[risk_property];
            });
            row[climate_layer_parameter] = combination['risk'];
            row['max_value'] = combination['risk']; 
            currentRows.push(row);

        }
    }
}

export const getDetailsTableRows = (assetRisks, riskType, metadataDefinitions) => {
    const rows = [];
    assetRisks.forEach((risk) => {
        appendClimateAndProbabilityRows(rows, risk);
        if (riskType == 1) {
            if (risk.parameter_value != null && risk.probability != null) {
                appendImpactSeverityRows(rows, risk, metadataDefinitions);
            }
            appendMaximumRisks(rows, risk);
        }
    });
    const formattedRows = rows.map(row => {
        const formattedRow = {};
        Object.keys(row).forEach((key) => {
            const formattedKey = key.startsWith('idf') ? parseIDFHeader(key) : parseColumnName(key);
            if (key === 'property') {
                formattedRow[formattedKey] = formatStringValue(row[key]);
            } else {
                formattedRow[formattedKey] = formatRiskValue(row[key]);  
            }
        });

        return formattedRow;
    });

    return formattedRows;
}

export const getDetailsTableRows2 = (assetRisks, metadataDefinitions) => {
    const rows = [];
    const asset_risk_properties = getAssetRiskProperties(metadataDefinitions); // Getting asset properties that are used in calculating risks

    assetRisks.forEach((risk) => { // going through each climate layer risk data
        const risk_combinations = risk.all_risk_combinations;
        if (risk.parameter_value != null && risk.probability != null) {
            appendRiskCombinations(rows, asset_risk_properties, risk_combinations);
        }
    });

    const formattedRows = rows.map(row => {
        const formattedRow = {};
        Object.keys(row).forEach((key) => {
            const formattedKey = key.startsWith('idf') ? parseIDFHeader(key) : parseColumnName(key);
            const columnDef = metadataDefinitions.find((metadata) => metadata.column_alias == key);
            if (key === 'property') {
                formattedRow[formattedKey] = formatStringValue(row[key]);
            } else {
                formattedRow[formattedKey] = columnDef && columnDef.is_risk_property ? formatStringValue(row[key]) : formatRiskValue(row[key]); 
            }
        });

        return formattedRow;
    });

    return formattedRows;

    //return rows;
}

export const getColourCoding = (text) => {
    if (text >= 0 && text < 3) {
        return rgbaToHex('rgba(168, 208, 141, .5)');
    } else if (text >= 3 && text < 5) {
        return rgbaToHex('rgba(0, 176, 240, .5)');
    } else if (text >= 5 && text < 6) { 
        return rgbaToHex('rgba(255, 230, 153, .5)');
    } else if (text >= 6 && text < 10) {
        return rgbaToHex('rgba(255, 255, 0, .5)');
    } else if (text >= 10 && text < 17) {
        return rgbaToHex('rgba(255, 119, 0, .5)');
    } else if (text >= 20) {
        return rgbaToHex('rgba(255, 0, 0, .5)');
    }
    return ''; // Return no color for non-numeric values
}


function rgbaToHex(rgba) {
    const parts = rgba.match(/rgba\((\d+), (\d+), (\d+), ([\d\.]+)\)/);
    if (!parts) return '#000000'; // Return black by default if invalid

    const r = parseInt(parts[1]).toString(16).padStart(2, '0');
    const g = parseInt(parts[2]).toString(16).padStart(2, '0');
    const b = parseInt(parts[3]).toString(16).padStart(2, '0');
    
    return `#${r}${g}${b}`;
}


export const tableBodyRiskScores = [
    // Header Row
    [
        {
            text: 'Risks Levels where Risk (R) = Probability (P) x Impact Severity (S)',
            style: 'tableHeader',
            colSpan: 4,
            alignment: 'center',
            fillColor: '#ff8c00', 
            bold: true
        }, {}, {}, {}
    ],

    // Data Rows
    [
        {text: 'Negligible Risk', fillColor: '#98fb98', alignment: 'center', style: 'metaDataText'},
        {text: 'R = 1 or 2', fillColor: '#98fb98', alignment: 'center', style: 'metaDataText'},
        {text: 'Negligible', alignment: 'center', style: 'metaDataText'},
        {text: 'Risk events do not require further consideration', alignment: 'center', style: 'metaDataText'}
    ],
    [
        {text: 'Low Risk', fillColor: '#0fc0fc', alignment: 'center', style: 'metaDataText'},
        {text: 'R = 3 or 4', fillColor: '#0fc0fc', alignment: 'center', style: 'metaDataText'},
        {text: 'Low', alignment: 'center', style: 'metaDataText'},
        {text: 'Controls likely not required', alignment: 'center', style: 'metaDataText'}
    ],
    [
        {text: 'Special Case', fillColor: '#ffdab9', color: '#ff0000', alignment: 'center', style: 'metaDataText', bold: true},
        {text: 'R = 5', fillColor: '#ffdab9', color: '#ff0000', alignment: 'center', style: 'metaDataText', bold: true},
        {text: 'Special Case', alignment: 'center', style: 'metaDataText'},
        {
            text: 'An extreme climatic event having a low probability of occurring, but which would cause very serious damage if it occurred. For example, a tornado or extreme rainfall that corresponds to a 1 in 100 years event, or; A climatic event which occurs frequently but has a negligible impact after an individual occurrence, however its repetitive frequency can cause premature wear of the physical components. For example, an increase in freeze-thaw cycles',
            alignment: 'center', style: 'metaDataText'
        }
    ],
    [
        {text: 'Moderate Risk', fillColor: '#ffff00', alignment: 'center', style: 'metaDataText'},
        {text: 'R = 6 to 9', fillColor: '#ffff00', alignment: 'center', style: 'metaDataText'},
        {text: 'Moderate', alignment: 'center', style: 'metaDataText'},
        {text: 'Some medium-term controls required to reduce risks to lower levels', alignment: 'center', style: 'metaDataText'}
    ],
    [
        {text: 'Significant Risk', fillColor: '#ffa500', alignment: 'center', style: 'metaDataText'},
        {text: 'R = 10 to 16', fillColor: '#ffa500', alignment: 'center', style: 'metaDataText'},
        {text: 'Significant Risk', alignment: 'center', style: 'metaDataText'},
        {text: 'High priority control measures required (To be considered, planned, and addressed in the near future)', alignment: 'center', style: 'metaDataText'}
    ],
    [
        {text: 'Major Risk', fillColor: '#ff0000', alignment: 'center', style: 'metaDataText'},
        {text: 'R = 20 to 25', fillColor: '#ff0000', alignment: 'center', style: 'metaDataText'},
        {text: 'Major Risk', alignment: 'center', style: 'metaDataText'},
        {text: 'Immediate controls and action required', alignment: 'center', style: 'metaDataText'}
    ]
];

export const tableBodyRiskAssessment_1 = [

    [
        {text: 'Severity of Impacts(S)', rowSpan: 5, alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText', bold: true},
        {text: 'Very Severe', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        {text: '5', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        //Data
        {text: '5', alignment: 'center', fillColor: '#ffdab9', style: 'metaDataText', color: '#ff0000', bold: true},
        {text: '10', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'},
        {text: '15', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'},
        {text: '20', alignment: 'center', fillColor: '#ff0000', style: 'metaDataText'},
        {text: '25', alignment: 'center', fillColor: '#ff0000', style: 'metaDataText'}
    ],

    [
        {}, 
        {text: 'Severe', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        {text: '4', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        //Data
        {text: '4', alignment: 'center', fillColor: '#0fc0fc', style: 'metaDataText'},
        {text: '8', alignment: 'center', fillColor: '#ffff00', style: 'metaDataText'},
        {text: '12', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'},
        {text: '16', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'},
        {text: '20', alignment: 'center', fillColor: '#ff0000', style: 'metaDataText'}
    ],
    [
        {}, 
        {text: 'Moderate', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        {text: '3', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        //Data
        {text: '3', alignment: 'center', fillColor: '#0fc0fc', style: 'metaDataText'},
        {text: '6', alignment: 'center', fillColor: '#ffff00', style: 'metaDataText'},
        {text: '9', alignment: 'center', fillColor: '#ffff00', style: 'metaDataText'},
        {text: '12', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'},
        {text: '15', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'}
    ],
    [
        {}, 
        {text: 'Minor', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        {text: '2', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        //Data
        {text: '2', alignment: 'center', fillColor: '#98fb98', style: 'metaDataText'},
        {text: '4', alignment: 'center', fillColor: '#0fc0fc', style: 'metaDataText'},
        {text: '6', alignment: 'center', fillColor: '#ffff00', style: 'metaDataText'},
        {text: '8', alignment: 'center', fillColor: '#ffff00', style: 'metaDataText'},
        {text: '10', alignment: 'center', fillColor: '#ffa500', style: 'metaDataText'}
    ],
    [
        {}, 
        {text: 'Measurable', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        {text: '1', alignment: 'center', fillColor: '#b0c4de', style: 'metaDataText'},
        //Data
        {text: '1', alignment: 'center', fillColor: '#98fb98', style: 'metaDataText'},
        {text: '2', alignment: 'center', fillColor: '#98fb98', style: 'metaDataText'},
        {text: '3', alignment: 'center', fillColor: '#0fc0fc', style: 'metaDataText'},
        {text: '4', alignment: 'center', fillColor: '#0fc0fc', style: 'metaDataText'},
        {text: '5', alignment: 'center', fillColor: '#ffdab9', style: 'metaDataText', color: '#ff0000', bold: true}
    ]
];

export const tableBodyRiskAssessment_2 = [
    [
        {text: '', fillColor: '#ffffff', margin: [0, 8]}, {text: '', fillColor: '#ffffff'}, {text: '', fillColor: '#ffffff'},
        //Y Axis
        {text: '1', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: '2', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: '3', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: '4', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: '5', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'}
    ],
    [
        {text: '', fillColor: '#ffffff', margin: [0, 8]}, {text: '', fillColor: '#ffffff'}, {text: '', fillColor: '#ffffff'},
        //Y Axis
        {text: 'Very Low', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: 'Low ', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: 'Moderate', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: 'High', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'},
        {text: 'Very High', alignment: 'center', fillColor: '#dda0dd', style: 'metaDataText'}
    ],
    [
        {text: '', fillColor: '#ffffff', margin: [0, 8]}, {text: '', fillColor: '#ffffff'}, {text: '', fillColor: '#ffffff'},
        //Y Axis
        {text: 'Probability/Likelihood of Occurence (P)', alignment: 'center', colSpan: 5, fillColor: '#9370db', bold: true},
        {}, {}, {}, {}
    ]
  
];
//widths: ['20%', '40%', '8%', '8%', '8%', '8%', '8%'],
export const appendixC_ClimateRiskScore = [
                // Climate Risk Score header
                [
                    { border: [false, false, false, true], text: 'Climate Risk Score', style: 'metaDataText', colSpan: 2, bold: true }, {}, 
                    { border: [false, false, false, true], text: '1', style: 'metaDataText', bold: true },
                    { border: [false, false, false, true], text: '2', style: 'metaDataText', bold: true },
                    { border: [false, false, false, true], text: '3', style: 'metaDataText', bold: true },
                    { border: [false, false, false, true], text: '4', style: 'metaDataText', bold: true },
                    { border: [false, false, false, true], text: '5', style: 'metaDataText', bold: true },
                ],
                // Data Rows for Temperature
                [
                    { rowSpan: 9, border: [false, true, true, true], text: 'Temperature', style: 'metaDataText', bold: true },
                    { text: 'Freeze-Thaw Cycles', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' },
                    { text: '80', style: 'metaDataText' },
                    { text: '120', style: 'metaDataText' },
                    { text: '160', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Heat Waves (#days)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' },
                    { text: '60', style: 'metaDataText' },
                    { text: '80', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Maximum Temperature', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '0.8', style: 'metaDataText' },
                    { text: '1.6', style: 'metaDataText' },
                    { text: '2.4', style: 'metaDataText' },
                    { text: '3.2', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Days Greater than 30°C (#days)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' },
                    { text: '60', style: 'metaDataText' },
                    { text: '80', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Heating Degree Days', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '2000', style: 'metaDataText' },
                    { text: '4000', style: 'metaDataText' },
                    { text: '6000', style: 'metaDataText' },
                    { text: '8000', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Cold Wave (#days)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '2', style: 'metaDataText' },
                    { text: '5', style: 'metaDataText' },
                    { text: '8', style: 'metaDataText' },
                    { text: '11', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Minimum Temperature (% Change)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '0.8', style: 'metaDataText' },
                    { text: '1.6', style: 'metaDataText' },
                    { text: '2.4', style: 'metaDataText' },
                    { text: '3.2', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Days Less than -25°C (#days)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '50', style: 'metaDataText' },
                    { text: '100', style: 'metaDataText' },
                    { text: '150', style: 'metaDataText' },
                    { text: '200', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Cooling Degree Days', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '100', style: 'metaDataText' },
                    { text: '200', style: 'metaDataText' },
                    { text: '300', style: 'metaDataText' },
                    { text: '400', style: 'metaDataText' }
                ],
                
                //Data Rows for Precipitation
                [
                    { rowSpan: 7, border: [false, true, true, true], text: 'Precipitation', style: 'metaDataText', bold: true },
                    { text: 'Annual Precipitation (mm)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '400', style: 'metaDataText' },
                    { text: '800', style: 'metaDataText' },
                    { text: '1200', style: 'metaDataText' },
                    { text: '1600', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: '1-Day Max Precipitation (mm)', style: 'metaDataText', bold: true },
                    { text: '1', style: 'metaDataText' },
                    { text: '25', style: 'metaDataText' },
                    { text: '50', style: 'metaDataText' },
                    { text: '75', style: 'metaDataText' },
                    { text: '100', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: '5-Day Max Precipitation (mm)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '50', style: 'metaDataText' },
                    { text: '100', style: 'metaDataText' },
                    { text: '150', style: 'metaDataText' },
                    { text: '200', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Average Snow Depth (cm)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '4', style: 'metaDataText' },
                    { text: '8', style: 'metaDataText' },
                    { text: '12', style: 'metaDataText' },
                    { text: '16', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Maximum Snow Depth (cm)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' },
                    { text: '60', style: 'metaDataText' },
                    { text: '80', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Extreme Snowfall (cm)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '80', style: 'metaDataText' },
                    { text: '160', style: 'metaDataText' },
                    { text: '240', style: 'metaDataText' },
                    { text: '320', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Annual Freezing Rain (mm)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '10', style: 'metaDataText' },
                    { text: '30', style: 'metaDataText' },
                    { text: '50', style: 'metaDataText' },
                    { text: '80', style: 'metaDataText' }
                ],
                
                //Data Rows for Drought
                [
                    { rowSpan: 3, border: [false, true, true, true], text: 'Drought', style: 'metaDataText', bold: true },
                    { text: 'Dry Days', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '0.19', style: 'metaDataText' },
                    { text: '0.39', style: 'metaDataText' },
                    { text: '0.59', style: 'metaDataText' },
                    { text: '0.79', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'SPEI', style: 'metaDataText', bold: true },
                    { text: '10', style: 'metaDataText' },
                    { text: '2', style: 'metaDataText' },
                    { text: '1', style: 'metaDataText' },
                    { text: '-1', style: 'metaDataText' },
                    { text: '-2', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'CMI', style: 'metaDataText', bold: true },
                    { text: '100', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '-19', style: 'metaDataText' },
                    { text: '-39', style: 'metaDataText' }
                ],
                
                // Data Rows for Miscellaneous
                [
                    { rowSpan: 11, border: [false, true, true, true], text: 'Temperature', style: 'metaDataText', bold: true },
                    { text: 'Strong Winds (# Events)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '10', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '30', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Thunderstorms', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '10', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '30', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Lightning Days', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '10', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' },
                    { text: '30', style: 'metaDataText' },
                    { text: '40', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Lightning Density', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '0.2', style: 'metaDataText' },
                    { text: '0.4', style: 'metaDataText' },
                    { text: '0.6', style: 'metaDataText' },
                    { text: '0.8', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Tornadoes', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '5', style: 'metaDataText' },
                    { text: '10', style: 'metaDataText' },
                    { text: '15', style: 'metaDataText' },
                    { text: '20', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: '100-Year Flood', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '2.5', style: 'metaDataText' },
                    { text: '5', style: 'metaDataText' },
                    { text: '7.5', style: 'metaDataText' },
                    { text: '10', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Coastal sensitivity', style: 'metaDataText', bold: true },
                    { text: '-1000', style: 'metaDataText' },
                    { text: '-500', style: 'metaDataText' },
                    { text: '-150', style: 'metaDataText' },
                    { text: '150', style: 'metaDataText' },
                    { text: '500', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Mean Wave Height', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '0.25', style: 'metaDataText' },
                    { text: '0.75', style: 'metaDataText' },
                    { text: '1.5', style: 'metaDataText' },
                    { text: '2.25', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Sea Level Rise', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '0.2', style: 'metaDataText' },
                    { text: '0.4', style: 'metaDataText' },
                    { text: '0.6', style: 'metaDataText' },
                    { text: '0.8', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Storm Surge (100y Residual)', style: 'metaDataText', bold: true },
                    { text: '0.5', style: 'metaDataText' },
                    { text: '1', style: 'metaDataText' },
                    { text: '1.5', style: 'metaDataText' },
                    { text: '2', style: 'metaDataText' },
                    { text: '2.5', style: 'metaDataText' }
                ],
                [
                    { border: [false, true, true, true], text: '', style: 'metaDataText' },
                    { text: 'Storm Surge (100y TWL)', style: 'metaDataText', bold: true },
                    { text: '0', style: 'metaDataText' },
                    { text: '1.5', style: 'metaDataText' },
                    { text: '3', style: 'metaDataText' },
                    { text: '4.5', style: 'metaDataText' },
                    { text: '6', style: 'metaDataText' }
                ],
];

//widths: ['30%','20%', '50%'],
export const appendixC_ClimateParameterDefinitions = [
    [
        {text: 'Parameter', style: 'metaDataText', bold: true  },
        {text: 'Metric', style: 'metaDataText', bold: true  },
        {text: 'Measured by', style: 'metaDataText', bold: true  },
    ],
    //Temperatures
    [
        {text: 'Temperatures', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Diurnal Temperature Variability Days', style: 'metaDataText'},
        {text: 'Number of days experiencing a diurnal temperature variability of greater than or equal to 15°C each year. A threshold range of 15°C is considered representative of extreme or phenomenal diurnal temperature variation.', style: 'metaDataText'},
    ],
    
    //Cold Extremes
    [
        {rowSpan: 3, text: 'Cold Extremes', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Very Cold Days', style: 'metaDataText'},
        {text: 'Number of days per year when the daily minimum temperature is less than or equal to -25°C.', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Very Cold Days', style: 'metaDataText'},
        {text: 'Number of days per year when the daily minimum temperature is less than or equal to -15°C.', style: 'metaDataText'},
    ],
                [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Cold Waves', style: 'metaDataText'},
        {text: 'A Winter Day is defined as a day when the temperature drops to at least -15 °C. For the purposes of this assessment, the definition of a cold wave was deemed to be the occurrence of three or more consecutive cold days (<-15°C). ', style: 'metaDataText'},
    ],
    
    //Warm Extremes
    [
        {rowSpan: 2, text: 'Warm Extremes', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Very Hot Days', style: 'metaDataText'},
        {text: 'Number of days per year when the daily maximum temperature is greater than or equal to 30°C (“Hot Days”)', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Heat Waves', style: 'metaDataText'},
        {text: 'A meteorological heat wave is defined using the Environment and Climate Change Canada definition as three or more consecutive days in which the maximum temperature is greater than or equal to 30°C. ', style: 'metaDataText'},
    ],
    
    //Freeze-Thaw Cycles
    [
        {text: 'Freeze-Thaw Cycles', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Freeze-Thaw Cycles', style: 'metaDataText'},
        {text: 'Days where the maximum daily temperature > 0°C and the minimum daily temperature < 0°C', style: 'metaDataText'},
    ],
    
    //Total Precipitation
    [
        {text: 'Total Precipitation', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Annual & Seasonal Precipitation', style: 'metaDataText'},
        {text: 'Annual & seasonal total precipitation (including both rain and snow) in mm', style: 'metaDataText'},
    ],
    
    //Heavy Precipitation
    [
        {text: 'Heavy Precipitation (IDF)', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'IDF', style: 'metaDataText'},
        {text: 'All return periods and durations are analyzed including 200- and 500-year return periods. ', style: 'metaDataText'},
    ],
    
    //Snow
    [
        {rowSpan: 3, text: 'Cold Extremes', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Annual Snowfall', style: 'metaDataText'},
        {text: 'Annual total snowfall in cm', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Snow Depth', style: 'metaDataText'},
        {text: 'Winter mean snow depth (Dec-Feb), seasonal mean snow depth (Oct-Apr) & Annual maximum snow cover depth in cm', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Rain on Snow', style: 'metaDataText'},
        {text: 'A rain on snow day (ROS) is defined as rain (as well as rain/snow mix) falling on at least 1 cm of snowpack over a given day. We therefore looked at the mean seasonal (Oct-Apr) daily occurrence of these events', style: 'metaDataText'},
    ],
    
    //Winter Rain
    [
        {text: 'Winter Rain', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Winter Rain', style: 'metaDataText'},
        {text: 'Mean amount of rain (excludes drizzle) that falls between Dec-Feb in mm.', style: 'metaDataText'},
    ],
    
    //Annual Rainfall
    [
        {text: 'Annual Rainfall', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Rainfall', style: 'metaDataText'},
        {text: 'Mean amount of liquid precipitation that falls between Jan-Dec in mm.', style: 'metaDataText'},
    ],
    
    //Freezing Rain & Ice Accretion
    [
        {rowSpan: 2, text: 'Freezing Rain & Ice Accretion', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Freezing Rain', style: 'metaDataText'},
        {text: 'Number of hours with freezing rain & freezing precipitation', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Ice Accretion', style: 'metaDataText'},
        {text: 'Annual mean ice accretion in mm', style: 'metaDataText'},
    ],
    
    //Extreme Snow and Blizzards
    [
        {text: 'Extreme Snow and Blizzardsl', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Heavy Snow', style: 'metaDataText'},
        {text: 'Mean # of days per year with snow conditions (>25 cm).', style: 'metaDataText'},
    ],
    
    //Extreme Events
    [
        {rowSpan: 5, text: 'Extreme Events', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'High Winds', style: 'metaDataText'},
        {text: 'Maximum Annual instantaneous wind gust (days having wind gusts >63 km/h). ', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'High Winds', style: 'metaDataText'},
        {text: 'Maximum Annual instantaneous wind gust (days having wind gusts >90 km/h). ', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Tornadoes', style: 'metaDataText'},
        {text: 'Tornado frequency and severity (Fujita Scale)', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Hurricanes', style: 'metaDataText'},
        {text: 'Hurricane frequency and severity (Saffir-Simpson Hurricane Wind Scale)', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Hail', style: 'metaDataText'},
        {text: 'Annual number of hail days', style: 'metaDataText'},
    ],
    
    //Drought/Dry Period
    [
        {rowSpan: 3, text: 'Drought/Dry Period', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Consecutive Dry Days', style: 'metaDataText'},
        {text: 'The number of periods with more than 5 Consecutive Dry Days is defined as the number of times when daily precipitation totals are less than 1 mm a day for six or more days straight.', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'SPEI', style: 'metaDataText'},
        {text: 'Annual SPEI values ', style: 'metaDataText'},
    ],
    [
        {text: '', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Max Consecutive Dry Days', style: 'metaDataText'},
        {text: 'Maximum Number of Consecutive Dry Days is defined as the longest spell of days where less than 1 mm of precipitation falls daily', style: 'metaDataText'},
    ],
    
    //Wildfires
    [
        {text: 'Wildfires', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Wildfires', style: 'metaDataText'},
        {text: 'Number of wildfire events per year (> 200 hectares)', style: 'metaDataText'},
    ],
    
    //Thunderstorm/Lightning
    [
        {text: 'Thunderstorm/Lightning', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Lightning', style: 'metaDataText'},
        {text: 'Annual lightning days (incorporates cloud to ground strikes)', style: 'metaDataText'},
    ],
    
    //Riverine Flooding
    [
        {text: 'Riverine Flooding', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Flooding', style: 'metaDataText'},
        {text: 'Number of 10-, 25-, 50-,100-, and 350-year floods at Ottawa River at Britannia, Gatineau, Rideau River, Chat Falls, and Carillon, plus high-level view of how changing byproduct climate parameters will affect future flooding conditions ', style: 'metaDataText'},
    ],
    
    //River Shoreline Erosion
    [
        {text: 'River Shoreline Erosion', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Erosion', style: 'metaDataText'},
        {text: 'Looked at soil erosion index & parameters that affect erosion such as precipitation (runoff), temperatures, wind, and snow cover.', style: 'metaDataText'},
    ],
    
    //Biofouling
    [
        {text: 'Biofouling', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Biofouling', style: 'metaDataText'},
        {text: 'Parameters that help aid biofouling such as temperatures', style: 'metaDataText'},
    ],

    //Fog/Visibility 
    [
        {text: 'Biofouling', style: 'metaDataText', fillColor: '#76b5c5' },
        {text: 'Heavy Fog', style: 'metaDataText'},
        {text: 'Annual number of fog events reducing visibility <1 km & 0.4 km ', style: 'metaDataText'},
    ],
];